.purchase-datatable td.MuiBox-root {
  padding-right: 0;
  padding-left: 0;
}

.purchase-datatable td div.MuiBox-root {
  width: 10rem;
}

.purchase-datatable td:last-child div.MuiBox-root {
  width: 2rem;
}

.purchase-clear {
  cursor: pointer;
  font-size: 14px !important;
}

.purchase-download-preview-save {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.purchase-upload-clear {
  margin-bottom: 10px;
}

.purchase-custom-grid-form {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  grid-template-rows: repeat(14, min-content);
  gap: 10px 10px;
  width: 97%;
}

.purchase-from {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 1;
}

.purchase-page {
  text-align: end;
  margin-top: 1rem;
  grid-column-start: 3;
  grid-column-end: 5;
  grid-row-start: 1;
}

.purchase-invoice-number {
  grid-column-start: 3;
  grid-column-end: 4;
  grid-row-start: 2;
}

.purchase-invoice-date {
  grid-column-start: 4;
  grid-column-end: 5;
  grid-row-start: 2;
}

.purchase-buyer {
  grid-column-start: 3;
  grid-column-end: 5;
  grid-row-start: 3;
}

.purchase-to {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 2;
}

.purchase-method-of-dispatch {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 4;
  grid-row-end: 4;
}

.purchase-type-of-shipment {
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 4;
  grid-row-end: 4;
}

.purchase-port-loading {
  grid-column-start: 3;
  grid-column-end: 4;
  grid-row-start: 4;
  grid-row-end: 4;
}

.purchase-port-discharge {
  grid-column-start: 4;
  grid-column-end: 5;
  grid-row-start: 4;
  grid-row-end: 4;
}

.purchase-data-table {
  grid-column-start: 1;
  grid-column-end: 5;
  grid-row-start: 5;
}

.purchase-unitType-price-amount {
  grid-column-start: 4;
  grid-column-end: 5;
  grid-row-start: 6;
}

.purchase-additional-inform {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 7;
  grid-row-end: 11;
}

.purchase-place-of-issue {
  grid-column-start: 3;
  grid-column-end: 3;
  grid-row-start: 7;
}

.purchase-date {
  grid-column-start: 4;
  grid-column-end: 5;
  grid-row-start: 7;
}

.purchase-signatory-company {
  grid-column-start: 3;
  grid-column-end: 5;
  grid-row-start: 8;
  grid-row-end: 8;
}

.purchase-authorized-Signatory {
  grid-column-start: 3;
  grid-column-end: 5;
  grid-row-start: 9;
  grid-row-end: 9;
}

.purchase-signature {
  grid-column-start: 3;
  grid-column-end: 5;
  grid-row-start: 11;
}

.purchase-bank-details {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 10;
  grid-row-end: 12;
}

.purchase-signatureCanvas {
  background-color: #0f1535 !important;
  color: white;
  width: 70%;
  height: 125px;
  border: 0.0625rem solid rgba(226, 232, 240, 0.3);
  border-radius: 0.9375rem;
}

.purchase-add-data-table-row label {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 10px;
  cursor: pointer;
  font-size: 14px;
}

.purchase-add-data-table-row label svg {
  width: 22px;
  height: 22px;
}

.purchase-add-from label {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 10px;
  cursor: pointer;
  font-size: 14px;
}

.purchase-add-from label svg {
  width: 22px;
  height: 22px;
}

.purchase-delete-icon-style {
  cursor: pointer;
}
