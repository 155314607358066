.custom-chart-classes .apexcharts-legend-text {
  color: white !important;
}

/* .custom-size span {
  display: inline-block;
  width: 172px;
} */

/* .custom-size h6 {
  height: 34px;
} */
